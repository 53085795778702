import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { TokenProviderToken } from '@app/models/user/tokenProviderToken.class';
import { UserB2CPayload } from '@app/models/user/NewUserB2CPayload.class';

@Injectable()
export class TokenProviderService {
    private uri: string = `${environment.routes.tokenProviderEndpoint}/token`;
    constructor(private http: HttpClient) { }

    public getToken(tokenPayload: UserB2CPayload): Observable<TokenProviderToken> {
      return this.http.post<TokenProviderToken>(`${this.uri}`, tokenPayload);
    }
}
