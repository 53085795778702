import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { PaginatedList } from '@app/models/common/paginated-list.class';
import { User } from '@app/models/user/user.class';
import { Observable } from 'rxjs';

@Injectable()
export class UserManagementService {
  private uri: string = `${environment.routes.apiEndpoint}/authorisation`;
  constructor(private http: HttpClient) { }

  public getUser(userId: string): Observable<User> {
    return this.http.get<User>(`${this.uri}/graph/users/${userId}`);
  }

  public getUsers(userSearch: string | null = null, pageSize: number = 10, pageIndex: number = 0): Observable<PaginatedList<User>> {
    let filters = `&PageSize=${pageSize}&PageIndex=${pageIndex + 1}`;
    if (userSearch) filters += `&UserQuery=${userSearch}`;

    return this.http.get<PaginatedList<User>>(`${this.uri}/graph/users?${filters}`);
  }

  public async updateUser() { // user: User
    console.error('Not implemented yet');
  }

  public async deleteUser() { // user: User
    console.error('Not implemented yet');
  }
}
