import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AddRoles, RemoveRoles } from "@app/models/authorisation/authorisation.class";
import { PaginatedList } from "@app/models/common/paginated-list.class";
import { Role } from "@app/models/common/role.class";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class AuthorisationService {
  private uri: string = `${environment.routes.apiEndpoint}/authorisation/roles`;

  constructor(private http: HttpClient) { }

  public getRoles(): Observable<PaginatedList<Role>> {
    return this.http.get<PaginatedList<Role>>(`${this.uri}`);
  }

  public addRoles(body: AddRoles[]) {
    return this.http.post(`${this.uri}/allocations/batch`, body);
  }

  public deleteRoles(body: RemoveRoles) {
    return this.http.delete(`${this.uri}/allocations/user/${body.userId}`, { body: body });
  }
}
